import { createData, createStore } from './shopify/alpine';

import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import focus from "@alpinejs/focus";
import ui from "@alpinejs/ui";

window.Alpine = Alpine;
Alpine.plugin(ui);
Alpine.plugin(focus);
Alpine.plugin(collapse);

createStore();
createData();

Alpine.start();
