import {
	addLines,
	getOrCreateCart,
	removeLine,
	setCartAttributes,
	setCartNote,
	updateLineQuantity,
} from "./cart";

const createStore = (Alpine) => {
	window.Alpine.store("statamic.shopify.cart", {
		cartId: null,
		checkoutUrl: "",
		lineItems: [],
		count: 0,
		note: "",
		subtotal: null,
		loading: true,

		async init() {
			this.cartId = localStorage.getItem("statamic.shopify.cart.id");

			if (this.cartId) {
				this.updateFromResponse(await getOrCreateCart(this.cartId));
				return;
			}

			this.updateBasketQty();
		},

		ageCheck() {
			const ageCheckRequired = document.querySelector('.age-check-required');
			if (ageCheckRequired) {
				window.Alpine.store('modal').open = true;
				return false;
			}
			window.location = this.checkoutUrl;

		},

		bannerMessage(messageHtml, type = "success", timeout = 6000) {
			const banner = document.getElementById("ss-banner-message");

			let elements = this.htmlToElements(messageHtml);
			// remove if there is already content + unhide banner
			banner.innerHTML = "";
			banner.classList.remove("hidden");

			// Set type
			if (type === "error") {
				banner.classList.add("text-brand-scarlet");
			} else {
				banner.classList.add("text-brand-cobalt");
			}

			// Append elements
			elements.forEach((el) => {
				banner.appendChild(el);
			});

			// Hide after timeout
			setTimeout(() => {
				banner.innerHTML = "";
				banner.classList.remove("text-brand-scarlet", "text-brand-cobalt");
				banner.classList.add("hidden");
			}, timeout);
		},

		checkoutAgeConfirmed() {
			window.location = this.checkoutUrl;
		},

		htmlToElements(html) {
			let template = document.createElement("template");
			template.innerHTML = html;
			return template.content.childNodes;
		},

		formatCurrency(amount) {
			return "£" + parseFloat(amount).toFixed(2);
		},

		getItems() {
			return this.lineItems;
		},

		removeLine(id) {
			removeLine(this.cartId, id).then((response) => {
				this.updateFromResponse(response);
				this.bannerMessage("<p>Item removed successfully</p>");
			});
		},

		setAttributes(attrs) {
			setCartAttributes(this.cartId, attrs).then((response) =>
				this.updateFromResponse(response)
			);
		},

		setNote(note) {
			setCartNote(this.cartId, note).then((response) =>
				this.updateFromResponse(response)
			);
		},

		updateQuantity(id, quantity) {
			updateLineQuantity(this.cartId, id, quantity).then((response) => {
				this.updateFromResponse(response);
				this.bannerMessage("<p>Quantity updated</p>");
			});
		},

		updateBasketQty() {
			const countTargetDesktop = document.querySelector(
				"[data-ss-cart-count-desktop]"
			);
			const countTargetMobile = document.querySelector(
				"[data-ss-cart-count-mobile]"
			);
			countTargetDesktop.innerHTML = this.count;
			countTargetMobile.innerHTML = this.count;
		},

		updateFromResponse(checkout) {
			let mappedLineItems = [];
			for (let line of checkout.lines.edges) {
				line = line.node;

				let attrs = {};
				line.attributes.forEach((attr) => (attrs[key] = attr.value));

				let qty = line.quantity;

				mappedLineItems.push({
					id: line.id,
					productId: line.merchandise.product.id,
					title: line.merchandise.product.title,
					variant: {
						title: line.merchandise.title,
					},
					price: this.formatCurrency(line.cost.amountPerQuantity.amount),
					qty: qty,
					subtotal: this.formatCurrency(
						line.cost.amountPerQuantity.amount * qty
					),
					image: line.merchandise?.image?.url,
					attributes: attrs,
				});
			}

			this.cartId = checkout.id;
			this.lineItems = mappedLineItems;
			this.note = checkout.note;
			this.subtotal = this.formatCurrency(checkout.cost.totalAmount.amount);
			this.checkoutUrl = checkout.checkoutUrl;

			this.count = 0;
			this.lineItems.forEach((item) => (this.count = this.count + item.qty));

			this.loading = false;
			this.updateBasketQty();

			localStorage.setItem("statamic.shopify.cart.id", this.cartId);
		},
	});
};

const createData = (Alpine) => {
	window.Alpine.data("shopifyProduct", () => ({
		variantEl: null,

		init() {
			this.variantEl = this.$el.querySelector('[name="ss-product-variant"]');
		},

		async handleSubmit(target) {
			let variantId = this.variantEl.value;

			if (!variantId) {
				return;
			}

			let cart = await getOrCreateCart(
				window.Alpine.store("statamic.shopify.cart").cartId
			);
			let attributes = [];
			let qty = parseInt(this.$el.querySelector("#ss-product-qty").value);

			let response = await addLines(cart.id, [
				{
					attributes: attributes,
					merchandiseId: "gid://shopify/ProductVariant/" + variantId,
					quantity: qty,
				},
			]);

			window.Alpine.store("statamic.shopify.cart").updateFromResponse(response);
			window.Alpine.store("statamic.shopify.cart").bannerMessage('<p>Added to cart</p>');
		},

	}));
};

export { createData, createStore };
